$AcuminPro: 'Acumin Pro';
$AcuminProItalic: 'Acumin Pro Italic';
$AcuminProBold: 'Acumin Pro Bold';
$AcuminProBoldItalic: 'Acumin Pro Bold Italic';


@font-face {
  font-family: 'Acumin Pro';
  src:url("./acumin-pro/AcuminPro-Regular.eot");
  src:url("./acumin-pro/AcuminPro-Regular.eot?#iefix") format("embedded-opentype"),
      url("./acumin-pro/AcuminPro-Regular.woff2") format("woff2"),
      url("./acumin-pro/AcuminPro-Regular.woff") format("woff"),
      url("./acumin-pro/AcuminPro-Regular.ttf") format("truetype"),
      url("./acumin-pro/AcuminPro-Regular.svg#AcuminPro-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  vertical-align: baseline;
}

@font-face {
  font-family: "Acumin Pro Bold";
  src:url("./acumin-pro/AcuminPro-Bold.eot");
  src:url("./acumin-pro/AcuminPro-Bold.eot?#iefix") format("embedded-opentype"),
      url("./acumin-pro/AcuminPro-Bold.woff2") format("woff2"),
      url("./acumin-pro/AcuminPro-Bold.woff") format("woff"),
      url("./acumin-pro/AcuminPro-Bold.ttf") format("truetype"),
      url("./acumin-pro/AcuminPro-Bold.svg#AcuminPro-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  vertical-align: baseline;

}

@font-face {
  font-family: "Acumin Pro Italic";
  src: url("./acumin-pro/AcuminPro-Italic.eot");
  src: url("./acumin-pro/AcuminPro-Italic.eot?#iefix") format("embedded-opentype"),
       url("./acumin-pro/AcuminPro-Italic.woff2") format("woff2"),
       url("./acumin-pro/AcuminPro-Italic.woff") format("woff"),
       url("./acumin-pro/AcuminPro-Italic.ttf") format("truetype"),
       url("./acumin-pro/AcuminPro-Italic.svg#AcuminPro-Italic") format("svg");
  font-weight: normal;
  font-style: italic;
  vertical-align: baseline;

}

@font-face {
  font-family: "Acumin Pro Bold Italic";
  src: url("./acumin-pro/AcuminPro-BoldItalic.eot");
  src: url("./acumin-pro/AcuminPro-BoldItalic.eot?#iefix") format("embedded-opentype"),
       url("./acumin-pro/AcuminPro-BoldItalic.woff2") format("woff2"),
       url("./acumin-pro/AcuminPro-BoldItalic.woff") format("woff"),
       url("./acumin-pro/AcuminPro-BoldItalic.ttf") format("truetype"),
       url("./acumin-pro/AcuminPro-BoldItalic.svg#AcuminPro-BoldItalic") format("svg");
  font-weight: bold;
  font-style: italic;
  vertical-align: baseline;

}
