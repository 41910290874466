@import 'libs/theme/src/fonts/acumin-fonts';
@import 'libs/theme/src/fonts/roboto';

$fontWeight300: 300;
$fontWeight500: 500;
$fontWeight600: 600;
$fontWeightNormal: normal;
$fontWeightBold: bold;
$fontWeightLight: lighter;

$fontSizeExtremeSmall: 0.625rem;
$fontSizeExtraSmall: 0.75rem;
$fontSizeSmall: 0.875rem;
$fontSizeNormal: 1rem;
$fontSizeLarge: 1.125rem;
$fontSizeExtraLarge: 1.25rem;
$fontSizeExtremeLarge: 1.375rem;
$fontSizeMegaLarge: 2rem;
