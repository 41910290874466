@import './_color';
@import './_fonts';

.zc-dashed {
  background-image: linear-gradient(to right, gray 58%, transparent 0);
  background-position: 0 23px;
  background-repeat: repeat-x;
  background-size: 6px 1.2px;
}

.zc-small-sandwich {
  position: absolute;
  display: inline-flex !important;
  flex-direction: column;
  margin-top: .3rem;
  left: 0;
  width: 12px !important;
  height: 12px !important;
  color: $ZC_DustyGray;
}

.zc-strong {
  font-weight: $fontWeight600;
  color: $ZC_DarkestGray;
}

.modal:nth-of-type(even) {
  z-index: 1062 !important;
}
.modal-backdrop.show:nth-of-type(even) {
  z-index: 1061 !important;
}

a, a:link, a:active, a:hover, a:visited {
  color: $ZC_FiordBlue;
}
a:hover {
  cursor: default;
}