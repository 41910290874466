/* White color */
$ZC_White: #fff;
$ZC_WhiteLilac: #f1f3f9;
$ZC_WhiteLilac2: #f9fafd;
$ZC_WildSand: #f4f4f4;

/* Black color */
$ZC_Black: #212529;
$ZC_MineShaftBlack: #333;

/* Gray color */
$ZC_DarkestGray: #444;
$ZC_DarkGray: #747474;
$ZC_DarkGray2 : #777;
$ZC_DustyGray: #979797;
$ZC_SilverGray: #c5c5c5;
$ZC_SilverGray2: #bebebe;
$ZC_AltoGray: #e0e0e0;
$ZC_AltoGray2: #d8d8d8;
$ZC_ManateeGray: #8f95a6;
$ZC_LoblollyGray: #bdc4cb;

/* Green color */
$ZC_LightPaleGreen: #7cd991;
$ZC_SeaGreen: #2e7a52;
$ZC_PanacheGreen: #e5f3ec;
$ZC_SkepticGreen: #c2e3d3;
$ZC_SinbadGreen: #abd8c2;

/* Blue color */
$ZC_LighterSteelBlue: #cad1d5;
$ZC_LightestSteelBlue: #c9cbd3;
$ZC_DarkSteelBlue: #848f9b;
$ZC_DarkMidnightBlue: #202b37;
$ZC_FiordBlue: #425365;
$ZC_LinkBlue: #008cff;
$ZC_GeyserBlue: #dde2e4;
$ZC_PorcelainBlue: #f0f2f3;
$ZC_PorcelainBlue2: #e7eaeb;
$ZC_MischkaBlue: #dcdfe8;
$ZC_EbonyClayBlue: #2A3540;
$ZC_LighterBlue: #f1f3f9;
$ZC_BarelyBlue: #f6f8ff;


/* Red color */
$ZC_ValenciaRed: #d54747;
$ZC_WePeepRed: #f7dada;
$ZC_PippinRed: #ffe1e1;

/* Button colors */
$ZC_ButtonBasic: #e6f3ec;
$ZC_ButtonHover: #c2e2d3;
$ZC_ButtonFocus: #abd8c3;

$ZC_ButtonText: #44765d;

/* Primary Colors */
$ZC_Error: $ZC_ValenciaRed;

/* Card Header colors */
$ZC_AttentionHeader: #5bd076;
$ZC_NoticeHeader: $ZC_LinkBlue;
$ZC_DangerHeader: $ZC_ValenciaRed;

/* Theme colors */
$ZC_Active: $ZC_LinkBlue;
$ZC_Selected: $ZC_LinkBlue;
$ZC_Highlighted: $ZC_DarkestGray;

$ZC_Background_Clear: $ZC_White;
$ZC_Background_Muted: $ZC_WhiteLilac2;
$ZC_Background_Desktop: $ZC_BarelyBlue;
$ZC_Background_Danger: $ZC_PippinRed;

$ZC_Link: $ZC_LinkBlue;
$ZC_Button_Checked: $ZC_White;
$ZC_Button_Unchecked: $ZC_White;
$ZC_Button_Check: $ZC_EbonyClayBlue;