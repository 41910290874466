@import "./color";
@import "./fonts";
@import "./mixins";

button, .button {
    @extend .btn !optional;

    padding: .375rem 1rem;
    height: 40px;
    border-radius: 20px !important;
    font-family: $AcuminPro;
    font-size: $fontSizeSmall;
    font-weight: $fontWeight500;
    white-space: nowrap;
    cursor: pointer;

    &:active,
    &:focus {
        outline: none !important;
        box-shadow: none !important;
    }
}

.zc-button-addition,
.zc-button-download,
.zc-button-quote-viewer,
.zc-button-close-print-view {
    color: $ZC_DarkMidnightBlue !important;
    background-color: $ZC_PorcelainBlue;

    @include hover($ZC_GeyserBlue);
    @include active-focus($ZC_LighterSteelBlue);
}
@media print {
    .zc-button-close-print-view {
        display: none;
    }
}

.zc-button-cancellation {
    color: $ZC_DarkSteelBlue !important;
    border: 1px solid $ZC_DarkSteelBlue;
}

.zc-button-collapse {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-bottom: 3px;
    width: 20px;
    height: 20px;
    color: $ZC_DustyGray !important;
    border: 1px solid $ZC_LightestSteelBlue;
    border-radius: 4px !important;
}

.zc-button-deletion {
    color: $ZC_ValenciaRed !important;
    background-color: $ZC_WePeepRed;
}

.zc-button-edition {
    color: $ZC_FiordBlue !important;
    border: 1px solid $ZC_FiordBlue;

    @include hover($ZC_PorcelainBlue2);
    @include active-focus($ZC_GeyserBlue);
}

.zc-button-add,
.zc-button-feature-list-variety,
.zc-button-operation-list-variety {
    padding: 0;
    color: $ZC_DarkSteelBlue !important;
}

.zc-button-report-viewer,
.zc-button-workflow-cancellation {
    color: $ZC_LightestSteelBlue !important;
    border: 1px solid $ZC_LightestSteelBlue;
}

.zc-button-undo,
.zc-button-redo {
    padding: 0.5rem;
    width: 32px;
    height: 32px;
    color: $ZC_DarkSteelBlue;
    border: 1px solid $ZC_DarkSteelBlue;
}

.zc-button-reset {
    margin-left: 1rem;
    padding: 0.5rem;
    height: 32px;
    color: $ZC_DarkSteelBlue;
    border: 1px solid $ZC_DarkSteelBlue;

    &:nth-child(1) {
        padding: 0 0.5rem;
    }
}

.zc-button-saving {
    color: $ZC_DarkMidnightBlue !important;
    background-color: $ZC_PorcelainBlue;

    @include hover($ZC_GeyserBlue);
    @include active-focus($ZC_LighterSteelBlue);
}

.zc-button-submission {
    color: $ZC_SeaGreen !important;
    background-color: $ZC_PanacheGreen;

    @include hover($ZC_SkepticGreen);
    @include active-focus($ZC_SinbadGreen);
}

.zc-button-variety {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin-right: 5px;
    width: 15px;
    height: 15px;
    color: $ZC_DarkSteelBlue;
}

.zc-button-workflow-saving {
    color: $ZC_FiordBlue;
    background-color: $ZC_White;
}

.zc-button-disabled {
    opacity: .6 !important;
    pointer-events: none !important;
}
