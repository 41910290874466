@import './fonts';
@import './color';

.zc-card {
  border-top: 2px solid $ZC_DarkMidnightBlue;
  border-radius: 0;
  font-family: $AcuminPro;
}

.zc-card-attention {
  border-top: 2px solid $ZC_AttentionHeader;
  border-radius: 0;
  font-family: $AcuminPro;
}

.zc-card-title {
  padding: 0.75rem 1.25rem;
  color: $ZC_DarkMidnightBlue;

  h4 {
    font-size: $fontSizeExtremeSmall;
    font-weight: $fontWeight600;
    letter-spacing: 1px;
    margin: 0;
  }
}

.zc-card-body {

  *:not(no-over) {
    margin: 0;
    padding: 0;
  }

  h5 {
    color: $ZC_DarkGray;
    font-size: $fontSizeExtraSmall;
    font-weight: $fontWeight500;
  }

  p {
    margin: 0;
    color: $ZC_DarkestGray;
  }

}

.zc-hr {
  margin: 0;
}
