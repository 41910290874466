@mixin hover($color) {
  &:hover {
    &:not([disabled]) {
      background-color: $color;
    }
  }
}

@mixin active-focus($color) {

  &:active,
  &:focus {
    &:not([disabled]) {
      background-color: $color;
    }
  }
}
