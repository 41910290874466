@import 'libs/theme/src/lib/color';

* {
  scrollbar-color: $ZC_DarkSteelBlue $ZC_LightestSteelBlue;
  scrollbar-width: thin;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: $ZC_LightestSteelBlue;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background-color: $ZC_DarkSteelBlue;
  border-radius: 1rem;
}

.container-fluid {
  max-width: 1440px;
}

.zc-modal-sm {
  max-width: 400px !important;
}

.zc-modal-md {
  max-width: 600px;
}

.zc-dashed-input {
  border: none;
  border-bottom: 2px dashed #C7CAD2;
}

.zc-selected-item {
  border: 1px solid $ZC_FiordBlue !important;
}