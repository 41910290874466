$RobotoBold: 'Roboto Medium';
$Roboto: 'Roboto Regular';
$RobotoMediumItalic: 'Roboto Medium Italic';
$RobotoItalic: 'Roboto Italic';


@font-face {
  font-family: 'Roboto Medium';
  src: url('./roboto/Roboto-Medium.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
      url('./roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
      url('./roboto/Roboto-Medium.woff2') format('woff2'),
      url('./roboto/Roboto-Medium.woff') format('woff'),
      url('./roboto/Roboto-Medium.ttf') format('truetype'),
      url('./roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Regular';
  src: url('./roboto/Roboto-Regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
      url('./roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('./roboto/Roboto-Regular.woff2') format('woff2'),
      url('./roboto/Roboto-Regular.woff') format('woff'),
      url('./roboto/Roboto-Regular.ttf') format('truetype'),
      url('./roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto Medium Italic';
  src: url('./roboto/Roboto-MediumItalic.eot');
  src: local('Roboto Medium Italic'), local('Roboto-MediumItalic'),
      url('./roboto/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('./roboto/Roboto-MediumItalic.woff2') format('woff2'),
      url('./roboto/Roboto-MediumItalic.woff') format('woff'),
      url('./roboto/Roboto-MediumItalic.ttf') format('truetype'),
      url('./roboto/Roboto-MediumItalic.svg#Roboto-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto Italic';
  src: url('./roboto/Roboto-Italic.eot');
  src: local('Roboto Italic'), local('Roboto-Italic'),
      url('./roboto/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
      url('./roboto/Roboto-Italic.woff2') format('woff2'),
      url('./roboto/Roboto-Italic.woff') format('woff'),
      url('./roboto/Roboto-Italic.ttf') format('truetype'),
      url('./roboto/Roboto-Italic.svg#Roboto-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}
