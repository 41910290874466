@import "libs/theme/src/lib/color";
@import "libs/theme/src/lib/fonts";

.zc-form-page {
margin: 30px;

.zc-form-header {
  margin: 0;
  padding: 15px 0px;

  .zc-form-header-title {
      height: 16px;
      color: $ZC_DarkMidnightBlue;
      font-family: "Acumin Pro";
      font-size: $fontSizeLarge;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 19px;
      text-transform: uppercase;
    }
  }

  .zc-form-header-subtitle {
    height: 14px;
    color: $ZC_DarkMidnightBlue;
    font-family: "Acumin Pro";
    font-size: $fontSizeNormal;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
  }
}

.form-check-input.large-check {
  &, &input[type=checkbox] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    vertical-align: middle;
    outline: none;
    font-size: inherit;
    cursor: pointer;
    width: 2em;
    height: 2em;
    background: $ZC_Button_Unchecked;
    border-radius: 0.25em;
    border: 0.125em solid #555;
    position: relative;

    &:checked {
      background: $ZC_Button_Checked;

      &:after {
        content: "✓";
        position: absolute;
        font-size: 1rem;
        left: 0.5rem;
        color: $ZC_Button_Check;
        font-weight: 800;
      }
    }
  }
  
} 
